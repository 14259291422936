import React from 'react'
import { Box } from 'grommet'
import { useStaticQuery, graphql } from 'gatsby'
import { AccordionList, PageLayout } from '../../components'

const FAQPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query FAQ {
      postItems: allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: {
                eq: "faq"
              }
            }
          }, 
          sort: {
            fields: [frontmatter___category, frontmatter___order]
          }
        ) {
        posts: edges {
          post: node {
            frontmatter {
              title
              category
            }
            html
          }
        }
      }
    }
  `)

  const faqs = data.postItems.posts
  const categories = faqs.map(item => item.post.frontmatter.category).filter((v, i, a) => a.indexOf(v) === i)


  const dataItems = (category) => {
    return faqs
      .filter(item => item.post.frontmatter.category === category)
      .map(item => ({ title: item.post.frontmatter.title, description: item.post.html }) )
  }

  return (
    <PageLayout 
      location={location}
      title="Platform Security"
      description="Hippobyte keeps your business data safe with industry-leading security."
      backgroundColor="white"
      pageTheme="dark"
      pageHeading={{
        title: {
          label: "Frequently Asked Questions"
        }
      }}
    >
      <Box margin={{ bottom: "xlarge" }}>
        {
          categories.map((category, index) => {
            const items = dataItems(category)
            return (
              <AccordionList
                fontSize="large"
                color="black"
                margin={{ horizontal: "11vw" }}
                header={{
                  title: category
                }}
                items={items}
              />
            )
          })
        }
      </Box>
    </PageLayout>
  )
}


export default FAQPage